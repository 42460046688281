<template>
	<main>
		<section
			class="cart-page container-white"
			:class="{'mobile-container': mobileCheck()}"
		>
			<div class="container-base">
				<div class="cart-page__swipe-wrapper">
					<v-touch
						ref="tapper"
						v-on:swipeup="swipeUp"
						v-on:swipedown="swipeDown"
					>
						<div
							class="cart-page__swipe-content"
							:class="{'cart-page__swipe-content--priority': showMobileRest,}"
						>
							<div
								class="cart-page__swipe-content-wr"
								:class="{ 'cart-page__swipe-content-wr--fixed': showMobileRest }"
								ref="swipeRest"
							>
								<div
									class="cart-page__swipe-top"
									@click="swipeDown"
								>
									<span class="cart-page__swipe-feature"></span>
								</div>
								<div>
									<Restaurants
										:restaurants="$store.state.restaraunts"
										@selectRestaurant="changeRest"
										:showMobileRest="showMobileRest"
									/>
								</div>
							</div>
						</div>
					</v-touch>
				</div>
				<div class="cart-page__breadcrumbs">
					<Breadcrumbs :linkArr="this.linkArr"/>
				</div>

				<div class="cart-page__content">
					<div class="cart-page__cart">
						<Cart @restChange="showRestChange($event)"/>
					</div>
					<Addons v-if="meals.length && !cartLoading && !currentBasket?.collective"/>
				</div>
			</div>
		</section>
		<Popup :visible.sync="showRestSelection">
			<Restaurants
				:restaurants="$store.state.restaraunts"
				@selectRestaurant="changeRest"
			/>
		</Popup>
		<BottomNavigation/>
	</main>
</template>

<script>
import Updater from '@/mixins/updater.js'
import {useYandexMetrika} from "@/composables";
import {mapActions, mapGetters, mapMutations} from 'vuex'
import RestaurantInfo from '../components/block/Menu/RestaurantInfo.vue'
import search from '@/mixins/search.js'
import BottomNavigation from "@/components/nav/BottomNavigation";
import {mobileCheck} from "@/helpers";
import {TIME_RANGES_EN} from "@/constants";

export default {
	setup()
	{
		const {yandexMetrika} = useYandexMetrika();

		return {yandexMetrika};
	},
	components: {
		RestaurantInfo,
		BottomNavigation,
		Restaurants: () => import('../components/popup/Restaurants.vue'),
		Breadcrumbs: () => import('@/components/block/Breadcrumbs.old.vue'),
		Addons: () => import('@/components/layout/Addons.vue'),
		Cart: () => import('@/components/layout/Cart.vue'),
		Tooltip: () => import('@/components/block/Tooltip.vue'),
	},
	mixins: [Updater, search],
	data()
	{
		return {
			showMobileRest: false,
			showRestSelection: false,
		}
	},
	computed:
		{
			...mapGetters({
				meals: 'getMeals',
				cartTimeRange: 'getCartTimeRange',
				cartLoading: 'getCartLoading',
				currentBasket: 'getCurrentBasket',
			}),
			// имя активного ресторана
			returnActiveRestaurantName()
			{
				for (let restaurant of this.$store.state.restaraunts)
					if (restaurant.id == this.$store.state.address.restarauntId)
						return restaurant.name;

				return '«Название Ресторана»'
			},
			linkArr()
			{
				const menu = TIME_RANGES_EN[this.cartTimeRange] || 'menu';

				return [
					{
						name: "Меню",
						link: `/${menu}`
					},
					{
						name: "Корзина",
						link: "/cart"
					},
				]
			}
		},
	methods: {
		...mapActions({
			getMenu: 'getMenu',
			getCart: 'getCart',
		}),
		...mapMutations({
			setTimeRange: 'setTimeRange',
		}),
		mobileCheck,
		swipeUp()
		{
			if (window.innerWidth <= 767)
			{
				this.showMobileRest = true;
				this.$refs.swipeRest.style.bottom = "0px";
				document.querySelector("body").classList.add("page-no-scroll");
			}
		},
		swipeDown()
		{
			if (window.innerWidth <= 767)
			{
				this.showMobileRest = false;
				this.$refs.swipeRest.style.bottom = "-" + this.swipeRestHeight + "px";
				document.querySelector("body").classList.remove("page-no-scroll");
			}
		},
		// Изменение ресторана
		showRestChange(value)
		{
			this.showRestSelection = value
		},
		async changeRest(rest)
		{
			this.showRestSelection = false;

			this.$store.commit('setRestarauntId', rest.id);
			await this.getMenu();
			await this.getCart();
			this.swipeDown();
		},
	},
	watch:
		{
			async cartTimeRange()
			{
				if (this.cartTimeRange)
				{
					this.setTimeRange(this.cartTimeRange);
					await this.getMenu(this.cartTimeRange)
				}
			}
		},
	/**
	 * если ресторан не выбран - переадресация
	 */
	async mounted()
	{
		await this.$store.dispatch('checkAddressFromCookie');

		await this.yandexMetrika();
		this.swipeRestHeight = this.$refs.swipeRest.offsetHeight;
		this.$refs.swipeRest.style.bottom = "-" + this.$refs.swipeRest.offsetHeight + "px";
		await this.initialActions();

		if (this.$store.state.address.restarauntId === false)
		{
			if (!this.$cookie.get("restid"))
			{
				this.$router.push("/");
				return;
			}
			this.$store.commit('setRestarauntId', this.$cookie.get("restid"));
		}
	},
}
</script>

<style lang="scss">
.cart-page__swipe-wrapper
{display: none;}

.cart-page__swipe-content
{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	z-index: 5;
	background: #FFFFFF;

	&--priority
	{z-index: 12;}
}

.cart-page__swipe-content-wr
{
	max-width: 354px;
	margin-left: 10px;
	background: #FFFFFF;
}

.cart-page__swipe-top
{
	position: absolute;
	height: 28px;
	width: calc(100% - 40px);
	margin: 0 20px 0 20px;
	background: #FFFFFF;
	text-align: center;
}

.cart-page__swipe-feature
{
	display: inline-block;
	width: 44px;
	height: 4px;
	background: #E3E3E3;
	border-radius: 16px;
}

.cart-page
{
	min-height: 88vh;

	padding: 20px 0;
	padding-bottom: 40px;
	background-color: #fff;

	//	h2, h3, span, div, a
	//	{ font-family: "GoogleSans-Regular", sans-serif;}
}

.cart-page__content
{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 36px;
}

.cart-page__info
{position: relative;}

.cart-page__info-icon:hover + .cart-page__info-tooltip
{
	z-index: 2;
	opacity: 1;
	visibility: visible;
}

.cart-page__info-icon
{
	@include ease(.125s);
	cursor: pointer;

	&:hover
	{
		svg
		{
			path
			{
				fill: #fff;
			}

			g
			{
				fill: $textColor;
				opacity: 1;
			}

			circle
			{
				stroke: $textColor;
			}
		}
	}
}

.cart-page__info-tooltip
{
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.05);
	top: 44px;
	left: -95px;
	@include ease(.125s);
}

.cart-page__label
{
	display: flex;
	font-family: $mainFont;
	font-size: 16px;
	color: $greyText;
	position: relative;
}

.cart-page__breadcrumbs
{
	margin-bottom: 33px;

	.breadcrumbs
	{
		padding-left: 0;
		margin: 0;
	}
}

.cart-page__cart
{
	width: 100%;
	margin-bottom: 62px;
}

@media (max-width: 1570px)
{
	.cart-page
	{padding: 40px 0;}
}

@media (max-width: 1279px)
{
	.cart-page
	{
		padding: 32px 0 20px;
		overflow: hidden;
	}
	.cart-page__cart
	{
		position: relative;
		z-index: 1;
	}
}

@media (max-width: 1024px)
{

	.cart-page
	{padding: 10px 0 20px;}
	.cart-page__breadcrumbs
	{display: none;}
	.cart-page__head
	{
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 16px;
	}
	.cart-page__cart
	{margin-bottom: 27px;}
	.cart-page__info-tooltip
	{
		left: unset;
		right: -30px;
	}
}

@media (max-width: 767px)
{
	.cart-page__swipe-wrapper
	{display: flex;}
	.cart-page__swipe-content-wr
	{
		margin-left: 0;
		position: fixed;
		z-index: 5;
		height: 0;
		width: 100%;
		max-width: unset;
		transition: .2s ease-in-out;

		&--fixed
		{
			height: 100%;
			padding-right: 0;
			padding-left: 0;
			padding-top: 0;
		}
	}
}

@media (max-width: 567px)
{
	.cart-page
	{
		padding-top: 24px;
		padding-bottom: 65px;
	}
}
</style>
